<template>
  <section
    class="exteriors"
    @mousewheel="mouseWheel"
    @touchstart="touschSwap"
    @touchend="touschSwap"
  >
    <!-- <Loader :loading="loading"></Loader> -->

    <!-- <transition
      name="exteriors-page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors-page" v-show="pageCount === 1">
        <BaseButton
          class="exteriors-page__button exteriors-page__button--back button--white button--ellipse button--lg"
          tabindex="1"
          text="Go back"
          @click="goBack"
        ></BaseButton>
        <img
          class="exteriors-page__image"
          v-webp="require('@/assets/images/house/exterior_1.jpg')"
          src="@/assets/images/house/exterior_1.jpg"
          alt="exterior_1"
        />
        <div class="exteriors-page__content exteriors-page__content--first">
          <div class="exteriors-page__body">
            <h2
              class="text-sub-title exteriors-page__body-sub-title exteriors-page__body-sub-title_margin"
            >
              Exteriors
            </h2>
            <h1 class="text-title exteriors-page__body-title">The Grotta Home</h1>
            <p class="text-description exteriors-page__body-description">
              "[I]ts forms are open rather than closed, equal parts glass and wall, [and] it
              performs as a stethoscope, registering nature’s every fact and nuance. The design
              delivers the environment to the Grottas not just as a view but as experience." -
              Joseph Giovannini
            </p>
            <BaseButton
              class="exteriors-page__button exteriors-page__button--start button--white button--ellipse button--lg"
              tabindex="2"
              text="Start Tour"
              @click="mouseWheel({ deltaY: 100 })"
            ></BaseButton>
          </div>
          <div class="exteriors-page__footer">
            <Copyright class="exteriors-page__footer-copyright">Photo by Tom Grotta</Copyright>
          </div>
        </div>
      </div>
    </transition> -->
    <transition
      v-for="page in pages"
      :key="`exterior_${page.id}`"
      name="exteriors-page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors-page" v-show="pageCount === page.id">
        <!-- <header class="exteriors-page__header">
          <h2 class="text-base exteriors-page__header-text">{{ page.title }}</h2>
        </header> -->
        <main class="exteriors-page__content">
          <img
            class="exteriors-page__image"
            v-webp="page.image"
            :src="page.image"
            :alt="`exterior_${page.id}`"
          />
        </main>
        <footer class="exteriors-page__footer">
          <Copyright class="exteriors-page__footer-copyright">
            Photo by {{ page.copyright }}
          </Copyright>
          <div class="exteriors-page__footer-nav">
            <!-- <IconButton
              v-if="page.id !== 1"
              class="exteriors-page__footer-button button--outline-white button--ellipse"
              @click.native="mouseWheel({ deltaY: -100 })"
              @keyup.enter="mouseWheel({ deltaY: -100 })"
            >
              <IconArrowRightLong
                class="exteriors-page__footer-nav-arrow exteriors-page__footer-nav-arrow--left"
              ></IconArrowRightLong>
            </IconButton>
            <div v-else class="exteriors-page__footer-button--hiden"></div> -->
            <IconButton
              class="exteriors-page__footer-button exteriors-page__footer-button-left"
              :class="{ 'exteriors-page__footer-button--disabled': page.id === 1 }"
              :disabled="page.id === 1"
              @click.native="mouseWheel({ deltaY: 100 })"
              @keyup.enter.native="mouseWheel({ deltaY: 100 })"
            >
              <IconArrowRightLong
                class="exteriors-page__footer-nav-arrow exteriors-page__footer-nav-arrow--left"
              ></IconArrowRightLong>
            </IconButton>
            <!-- <p class="text-description exteriors-page__footer-page">
              {{ `0${page.id}` }}
              <span class="text-description exteriors-page__footer-page_count">
                /{{ `0${pages.length}` }}
              </span>
            </p> -->
            <IconButton
              class="exteriors-page__footer-button exteriors-page__footer-button-right"
              @click.native="mouseWheel({ deltaY: 100 })"
              @keyup.enter.native="mouseWheel({ deltaY: 100 })"
            >
              <IconArrowRightLong
                class="exteriors-page__footer-nav-arrow exteriors-page__footer-nav-arrow--right"
              ></IconArrowRightLong>
            </IconButton>
          </div>
        </footer>
      </div>
    </transition>
    <transition
      name="exteriors-page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors-page" v-show="pageCount === 10">
        <img
          class="exteriors-page__image"
          v-webp="require('@/assets/images/house/exterior_12.jpg')"
          src="@/assets/images/house/exterior_12.jpg"
          alt="exterior_12"
        />
        <div class="exteriors-page__content exteriors-page__content--first">
          <!-- <div class="exteriors-page__body exteriors-page__body_top">
            <p class="text-base exteriors-page__body-text">Back of the House in Autumn sunshine</p>
          </div> -->
          <div class="exteriors-page__body exteriors-page__body_finished">
            <h2 class="text-sub-title exteriors-page__body-sub-title">Next: House History</h2>
            <div class="exteriors-page__body-footer">
              <BaseButton
                class="exteriors-page__body-footer-button button--white button--ellipse button--lg"
                tabindex="1"
                text="House History"
                @click="openHistoryPage"
              ></BaseButton>
              <BaseButton
                class="exteriors-page__body-footer-button button--outline-white button--ellipse button--lg"
                tabindex="2"
                text="Back to Start"
                @click="toStart"
              ></BaseButton>
              <div class="exteriors-page__body-footer-line"></div>
            </div>
          </div>
          <div class="exteriors-page__footer">
            <Copyright class="exteriors-page__footer-copyright">Photo by Jake Di Pietro</Copyright>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import Copyright from "@/components/Copyright.vue";
import IconPlay from "@/components/icons/IconPlay.vue";
import IconArrowRightLong from "@/components/icons/IconArrowRightLong.vue";

export default {
  components: {
    Loader,
    BaseButton,
    IconButton,
    Copyright,
    IconPlay,
    IconArrowRightLong,
  },
  data() {
    return {
      pageCount: 1,
      pagesCount: 10,
      loading: false,
      scrollDeltaY: 0,
      animationMove: false,
      play: false,
      pages: [
        {
          title: "From the Outside",
          id: 1,
          image: import("@/assets/images/house/exterior_2.jpg"),
          copyright: "Tom Grotta",
        },
        {
          title: "Front entrance to The Grotta House",
          id: 2,
          image: import("@/assets/images/house/exterior_3.jpg"),
          copyright: "Tom Grotta",
        },
        {
          title:
            "A view of the central two-story cylindrical space at the center of The Grotta House",
          id: 3,
          image: import("@/assets/images/house/exterior_4.jpg"),
          copyright: "Tom Grotta",
        },
        {
          title: "The Grotta House by night",
          id: 4,
          image: import("@/assets/images/house/exterior_5.jpg"),
          copyright: "Tom Grotta",
        },
        {
          title: "Looking into the two-story Living Room",
          id: 5,
          image: import("@/assets/images/house/exterior_6.jpg"),
          copyright: "Tom Grotta",
        },
        {
          title: "The Grotta House in Autumn",
          id: 6,
          image: import("@/assets/images/house/exterior_7.jpg"),
          copyright: "Tom Grotta",
        },
        {
          title: "The Grotta House in the fog",
          id: 7,
          image: import("@/assets/images/house/exterior_8.jpg"),
          copyright: "Tom Grotta",
        },
        {
          title: "Architect Richard Meier and client Lou Grotta",
          id: 8,
          image: import("@/assets/images/house/exterior_10.jpg"),
          copyright: "Scott Francis",
        },
        {
          title: "Back of the House",
          id: 9,
          image: import("@/assets/images/house/exterior_11.jpg"),
          copyright: "Jake Di Pietro",
        },
      ],
    };
  },
  head: {
    title: function () {
      return {
        inner: `GH - Outside`,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  beforeRouteEnter(to, from, next) {
    const pagesCount = 10;
    if (to.params.id > pagesCount || to.params.id < 1) next({ path: "/exteriors/1" });
    else
      next(vm => {
        vm.pageCount = +to.params.id;
      });
  },
  created() {
    this.pages.map(async page => {
      let respone = await page.image;
      page.image = respone.default;
    });
    document.addEventListener("keydown", this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keydown);
  },
  watch: {
    "$route.path": function () {
      if (+this.$route.params.id === 1) this.toStart();
    },
  },
  methods: {
    beforeEnter(el) {
      this.animationMove = true;
      el.classList.add(`exteriors-page__fade-enter${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
    },
    afterEnter(el) {
      el.classList.remove(`exteriors-page__fade-enter${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
      this.animationMove = false;
    },
    beforeLeave(el) {
      this.animationMove = true;
      el.classList.add(`exteriors-page__fade-leave${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
    },
    afterLeave(el) {
      el.classList.remove(`exteriors-page__fade-leave${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
      this.animationMove = false;
    },
    goBack() {
      this.$router.push({ path: "/" });
    },
    openHistoryPage() {
      this.$router.push({ path: "/about/the-history" });
    },
    toStart() {
      this.scrollDeltaY = -1;
      this.$router.push({ path: "/exteriors/1" });
      this.pageCount = 1;
    },
    mouseWheel(event) {
      if (!this.animationMove) {
        this.scrollDeltaY = event.deltaY;
        if (event.deltaY >= 30 && this.pageCount < this.pagesCount) {
          this.pageCount++;
          this.$router.push({ path: `/exteriors/${this.pageCount}` });
        }
        if (event.deltaY <= -30 && this.pageCount > 1) {
          this.pageCount--;
          this.$router.push({ path: `/exteriors/${this.pageCount}` });
        }
      }
    },
    keydown(event) {
      switch (event.code) {
        case "ArrowUp":
          this.mouseWheel({ deltaY: -100 });
          break;
        case "ArrowDown":
          this.mouseWheel({ deltaY: 100 });
          break;
        case "ArrowRight":
          this.mouseWheel({ deltaY: 100 });
          break;
        case "ArrowLeft":
          this.mouseWheel({ deltaY: -100 });
          break;
      }
    },
    touschSwap(event) {
      if (!this.animationMove) {
        if (event.type === "touchstart") {
          this.scrollDeltaY = event.changedTouches[0].clientY;
        }
        if (event.type === "touchend") {
          const newScrollDeltaY = event.changedTouches[0].clientY;
          const deltaY = this.scrollDeltaY - newScrollDeltaY;
          this.mouseWheel({ deltaY });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exteriors {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  &-page {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__fade {
      &-enter-active,
      &-leave-active {
        transition: all 1s ease;
      }
      &-enter {
        &-up {
          animation: pageEnterUp 1s ease-in-out;
        }
        &-down {
          animation: pageLeaveDown 1s ease-in-out;
        }
      }
      &-leave {
        &-up {
          animation: pageLeaveUp 1s ease-in-out;
        }
        &-down {
          animation: pageEnterDown 1s ease-in-out;
        }
      }
    }
    &__image {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: $xxs) {
        width: auto;
        max-width: none;
      }
      &--bg {
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
    &__button {
      width: max-content;
      min-width: 22rem;
      &--start {
        margin-top: 15px;
      }
      &--back {
        position: absolute;
        z-index: 999;
        left: 50px;
        top: 70px;
        height: 4rem;
        min-width: auto;
        font-size: 1.8rem;
        @media (max-width: $xxs) {
          left: 25px;
          top: 50px;
        }
      }
    }
    &__header {
      padding: 50px 50px 20px;
      min-height: calc(7rem + 70px);
      display: flex;
      align-items: center;
      flex-shrink: 0;
      @media (max-width: $xxs) {
        min-height: calc(6rem + 55px);
        padding: 100px 25px 15px;
      }
      &-text {
        max-width: 842px;
        @media (max-width: $xxl) {
          max-width: 720px;
        }
        @media (max-width: $xxs) {
          max-width: 60%;
          font-size: 1.8rem;
        }
      }
    }
    &__content {
      position: relative;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      &--first {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
    &__body {
      padding-left: 16.4rem;
      margin-bottom: 75px;
      width: max-content;
      @media (max-width: $lg) {
        width: 100%;
      }
      @media (max-width: $xxs) {
        padding: 0 25px;
        width: 100%;
      }
      &_top {
        height: 100%;
        margin-top: 13.3rem;
      }
      &_finished {
        padding-left: 38rem;
        width: 100%;
        @media (max-width: $xxs) {
          padding: 0 0 0 25px;
        }
      }
      &-title {
        margin-bottom: 22px;
      }
      &-sub-title {
        &_margin {
          margin-bottom: 20px;
        }
      }
      &-text {
        max-width: 842px;
        @media (max-width: $xxl) {
          max-width: 720px;
        }
        @media (max-width: $xxs) {
          max-width: 380px;
        }
      }
      &-description {
        max-width: 768px;
        @media (max-width: $xxs) {
          width: 100%;
          max-width: 380px;
        }
      }
      &-footer {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 5rem;
        @media (max-width: $xxs) {
          margin-top: 3rem;
          flex-wrap: wrap;
          gap: 10px 0;
        }
        &-button {
          min-width: 20rem;
          width: max-content;
          flex-shrink: 0;
          &.button--white {
            margin-right: 15px;
          }
          @media (max-width: $xxs) {
            min-width: 15rem;
          }
        }
        &-line {
          height: 1px;
          min-width: 2.9rem;
          width: 100%;
          background-color: $white;
          @media (max-width: $xxs) {
            max-width: 50%;
          }
        }
      }
    }
    &__footer {
      // position: relative;
      position: fixed;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px 0;
      padding: 20px 50px;
      @media (max-width: $xxs) {
        padding: 20px 25px 30px;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
      }
      &-nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 270px;
        background-color: rgba(17, 17, 17, 1);
        padding: 10px 50px;
        border-radius: 50px;
        @media screen and (max-width: $xxs) {
          position: static;
          left: 0;
          transform: none;
          max-width: 250px;
        }
        &-arrow {
          cursor: pointer;
          width: 36px;
          height: 12px;
          &::v-deep g {
            transition: all 0.3s ease;
            fill: $white;
          }
          &--right {
            transform: rotate(180deg) scale(1.1);
          }
          &--left {
            transform: scale(1.1);
          }
        }
      }
      &-button {
        width: max-content;
        height: max-content;
        padding: 10px;
        border: none;
        svg {
          &::v-deep g {
            fill: $white;
          }
        }
        &:hover {
          // background: $white;
          // &::v-deep g {
          //   fill: $black;
          // }
          background: transparent;
          &::v-deep g {
            fill: $white;
          }
        }
        &-right {
          &:hover {
            margin-right: -6px;
          }
        }
        &-left {
          &:hover {
            margin-left: -6px;
          }
        }
        &--hiden {
          width: 56px;
          height: 29px;
          padding: 10px;
        }
      }
      &-copyright {
        @media screen and (max-width: $xxs) {
          width: 100%;
        }
      }
      &-description {
        cursor: pointer;
        font-family: $fontBodoni;
        line-height: 2.44;
      }
      &-page {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.2;
        @media screen and (max-width: $xxs) {
        }
        &_count {
          font-size: 2rem;
          font-weight: 600;
          line-height: 2.2;
          opacity: 0.6;
        }
      }
    }
  }
}
@keyframes pageEnterUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes pageLeaveUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes pageEnterDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes pageLeaveDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>

